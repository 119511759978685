import axios from 'axios';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import axiosApi from '../api';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SHOW_MESSAGE } from '../constants/ActionTypes';
import { errorHandler } from '../helpers/apiErrorHandler';
import {
    loginConfirmedAction,
    Logout,
    LogoutUser,
} from '../store/actions/AuthActions';
import { getThemesettingsApi } from './CommonServices';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export const login = async (email, password, deviceToken) => {
    const postData = {
        email,
        password,
        deviceToken: deviceToken,
        deviceId: 'web',
        returnSecureToken: true,
    };
    return await axiosApi.post(
        `Api/login`,
        postData,
    )

}

export function formatError(errorResponse) {
    switch (errorResponse.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails, cb) {

    let tempObj = Object.assign({}, tokenDetails.data)
    tempObj.expire_time = new Date(
        new Date().getTime() + tempObj.expire_time,
    );
    localStorage.setItem('userDetails', JSON.stringify(tempObj));
    localStorage.setItem('token', tempObj.access_token);
    if (cb) cb('yes')
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        dispatch(LogoutUser(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
        return;
    }
    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expire_time);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
    dispatch(loginConfirmedAction(tokenDetails));
    dispatch(getThemesettingsApi((res) => { }));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}


export const forgotPassword = (email, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/forgot-password', email).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: res.data.message,
                });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
export const resetPassword = (data, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Api/reset-password', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: res.data.message,
                });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}