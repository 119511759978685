import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { store } from './store/store';
import ThemeContext from "./context/ThemeContext";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_spa from "./locales/spa/translation.json";
import common_en from "./locales/en/translation.json";
import moment from "moment-timezone";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import configureStore from './store/store';
import "react-circular-progressbar/dist/styles.css";

import swal from 'sweetalert';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

function isNumberOrDate(str) {
  if (str && (/^\d+(\.\d+)?$/.test(str))) return 'number';
  try {
    if (str && (!isNaN(new Date(str).getTime()))) return 'date';
  } catch (error) { }
  return 'Neither';
}


i18next.init({
  interpolation: {
    escapeValue: false,
    format: function (value, name) {
      let date = JSON.parse(JSON.stringify(value))
      if (isNumberOrDate(date) == 'date') {
        let timeZone = localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : moment.tz.guess()
        let dateFormat = localStorage.getItem('dateFormat') ? localStorage.getItem('dateFormat') : "DD-MM-YYYY"
        let timeFormat = localStorage.getItem('timeFormat') ? localStorage.getItem('timeFormat') : "24 Hours"

        if (timeFormat === '24 Hours') {
          timeFormat = 'HH'
        } else {
          timeFormat = 'hh'
        }

        if (name == 'dateonly') {
          return moment.tz(moment.tz(value, 'Europe/London'), timeZone).format(`${dateFormat}`)
        } else {
          return moment.tz(moment.tz(value, 'Europe/London'), timeZone).format(`${dateFormat} ${timeFormat}:mm:ss`)
        }
      }

      if (typeof value == 'number') {

        if (value < 0) {
          console.log(value)
        }
        let currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : 'INR'
        let symbol = localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : '₹'
        let position = localStorage.getItem('currencyposition') ? localStorage.getItem('currencyposition') : 'Left'
        let noOfDecimals = localStorage.getItem('decimalNumber') ? localStorage.getItem('decimalNumber') : '2'
        let separator = localStorage.getItem('decimalSeparator') ? localStorage.getItem('decimalSeparator') : 'Comma(,)'
        value = new Intl.NumberFormat(currency, { minimumFractionDigits: noOfDecimals, maximumFractionDigits: noOfDecimals }).format(value)

        if (separator == 'Comma(,)') {
          value = value.replace(/[,.]/g, m => (m === '.' ? ',' : '.'))
        }

        if (position == 'Left') {
          return value + ' ' + symbol
        } else {
          return symbol + ' ' + value
        }
      }
      return value;
    }
  },
  lng: localStorage.getItem('language') ? localStorage.getItem('language') : 'EN',                              // language to use
  resources: {
    EN: {
      common: common_en               // 'common' is our custom namespace
    },
    SPA: {
      common: common_spa
    },
  },
});

const store = configureStore()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeContext>
            <ToastContainer />
            <App />
          </ThemeContext>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
