export const imageUploadinLocal = true

 export const BASEURL = 'https://company-admin.delcibo.co.uk'
//export const BASEURL = 'http://192.168.1.39:3002'

// export const S3BUCKETNAME = 'single-foodel-beta'
// export const S3BUCKETNAME = 'single-foodel-development'
// export const S3BUCKETNAME = 'single-foodel-production'
export const S3BUCKETNAME = 'single-foodel-local'

// export const APIURL = `${BASEURL}/foodel-api/`
export const APIURL = `${BASEURL}/backend-api/`




export const S3ImageUpload = imageUploadinLocal ? `${APIURL}uploads/` : `https://${S3BUCKETNAME}.s3.eu-west-2.amazonaws.com/uploads/`

const mailchimpClientId = 421873324979
export const MailchimpUrl = `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${mailchimpClientId}&state={{state}}&redirect_uri=${BASEURL}/mailchimp/connect`


export const languageArr = ['EN', 'SPA']
export const timezoneArr = ['Local Time', 'America/Los_Angeles', 'Asia/Kolkata', 'Europe/London']
export const dateFormatArr = ['YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM-YYYY']
export const timeFormatArr = ['12 Hours', '24 Hours']
export const firstDayOfWeekArr = ['Sunday', 'Monday']
export const currencyArr = ['INR', 'USD', 'GBP']
export const currencyPositionArr = ['Left', 'Right']
export const decimalSeparatorArr = ['Dot(.)', 'Comma(,)']
export const decimalArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const weekendsArr = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const weekendsArrForPromotion = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const symbolsArr = ["e", "E", "+", "-"];
export const chartColors = ['#fc8019', "#EB5757", "#1FBF75", "#008ffb", "#775dd0", "#7F5B03"]
export const reasonCharLimit = 60;
export const pageManagePageList = ['About Us', 'Terms & Conditions', 'Privacy Cookies', 'Privacy Policy', 'Allergy']
export const userRole = [
  {
    id: 1,
    role: 'customer'
  },
  {
    id: 2,
    // role:'admin'
    role: 'restaurant'
  },
  {
    id: 3,
    role: 'admin'
  },
  {
    id: 4,
    role: 'driver'
  },
  {
    id: 5,
    role: 'user'
  }
]

export const modifierGroupType = [
  {
    id: 1,
    name: 'Free'
  },
  {
    id: 2,
    name: 'Paid'
  },
  {
    id: 3,
    name: 'FreePaid'
  }
]
