import { lazy, Suspense, useEffect } from 'react';


import Index from "./jsx";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';

import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import ResetPassword from './jsx/pages/ResetPassword';
import { toast } from 'react-toastify';
import firebase, { messaging } from '../src/jsx/pages/firebase';
import swal from 'sweetalert';
import { getOrderCountByStatusApi } from './services/orderService';

const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    const { error, message } = useSelector((state) => state.commonData)

    return (<>
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
      {message
        ? error
          ?
          toast.error(message, {
            toastId: "customId",
            autoClose: 5000,
          }) :
          toast.success(message, {
            toastId: "customId",
            autoClose: 5000,
          })
        : ''}
    </>
    );
  }
  return ComponentWithRouterProp;
}

function App(props) {
  const broadcastChannel = new BroadcastChannel('my-channel');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  if (props.isAuthenticated && location.pathname == '/login') {
    if (location.search) {
      navigate(decodeURIComponent(location.search.replace('?', '')))
    } else {
      navigate('/dashboard')
    }
  }

  const userDetails = localStorage.getItem('userDetails')
  useEffect(() => {
    if (userDetails) {
      checkAutoLogin(dispatch, navigate);
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      navigate(location.pathname)
    } else {
      if (location.pathname.split('/')[1].toLowerCase() != 'resetpassword' && location.pathname != '/forgotpassword') {
        let path = encodeURIComponent(window.location.href.split('/').pop())
        navigate({
          pathname: '/login',
          search: path,
        });
      }
    }
  }, []);

  useEffect(() => {
    broadcastChannel.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'GET_ORDER_COUNT') {
        dispatch(getOrderCountByStatusApi((res) => { }));
      }
    });
  }, [])


  messaging.onMessage((payload) => {
    dispatch(getOrderCountByStatusApi((res) => { }))
    swal({
      title: "You have new Order",
      text:
        "click ok to see new order",
      icon: "info",
      buttons: {
        confirm: 'Ok',
        cancel: 'Cancel'
      },
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        navigate('/orderMaster')
      }
    })
  });


  let routeblog = (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/forgotpassword' element={<ForgotPassword />} />
      <Route path='/resetpassword/:slug' element={<ResetPassword />} />
    </Routes>
  );

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

// export default connect((mapStateToProps)(App)); 
export default withRouter(connect(mapStateToProps)(App));

